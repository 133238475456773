import React from 'react'

const ScriptingIcon: React.FC = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.0957 16.4762L12.7235 14.8484L11.8339 13.9524L9.31006 16.4762L11.8339 19L12.7298 18.1104L11.0957 16.4762ZM16.3578 16.4762L14.73 18.1041L15.6196 19L18.1434 16.4762L15.6196 13.9524L14.7237 14.842L16.3578 16.4762Z'
        fill='#E9EBF8'
      />
      <path
        d='M8.04765 17.7381H4.26191V2.59513H9.30956V6.38087C9.31056 6.71525 9.44383 7.03564 9.68027 7.27208C9.91671 7.50852 10.2371 7.64179 10.5715 7.64279H14.3572V11.4285H15.6191V6.38087C15.6214 6.29795 15.6056 6.21553 15.5729 6.13928C15.5402 6.06304 15.4914 5.99478 15.4298 5.9392L11.0131 1.52251C10.9576 1.46089 10.8893 1.41206 10.8131 1.37938C10.7368 1.3467 10.6544 1.33095 10.5715 1.33322H4.26191C3.92754 1.33422 3.60715 1.46749 3.37071 1.70393C3.13427 1.94037 3.001 2.26076 3 2.59513V17.7381C3.001 18.0725 3.13427 18.3929 3.37071 18.6293C3.60715 18.8657 3.92754 18.999 4.26191 19H8.04765V17.7381ZM10.5715 2.84752L14.1048 6.38087H10.5715V2.84752Z'
        fill='#BCCEFD'
      />
    </svg>
  )
}

export default ScriptingIcon
