import React from "react";
import logo from './assets/Kubeshark-logo.svg';
import './Header.sass';
import * as UI from "../UI"
import Moment from 'moment';
import ErrorIcon from '@mui/icons-material/Error';
import { Link, Button, Typography } from "@mui/material";
import Tooltip from "../UI/Tooltip/Tooltip";
import { LicenseEnterpriseEdition, LicenseProEdition, LicenseOndemandEdition, LinkAdminConsole } from "../../consts";

import { NavDropdown } from "./NavDropdown/NavDropdown";
import { NavDivider } from "./NavDivider";
import { InvalidLicenseModal } from "../modals/InvalidLicenseModal/InvalidLicenseModal";
import { useRecoilValue } from "recoil";
import fullscreenViewAtom from "../../recoil/fullscreenView/atom";

interface HeaderProps {
  licenseEdition: string;
  licenseValid: boolean;
  licenseEnd: number;
  licenseMaxNodeCount: number;
  licenseCurrentNodeCount: number;
  licenseNodeLimit: number;
  licenseCapacity: number;
}

export const Header: React.FC<HeaderProps> = ({ licenseValid, licenseEnd, licenseMaxNodeCount, licenseCurrentNodeCount, licenseNodeLimit, licenseEdition, licenseCapacity  }) => {
  const upgrade = licenseCurrentNodeCount > licenseNodeLimit && licenseNodeLimit >= 0

  const fullscreenView = useRecoilValue(fullscreenViewAtom)

  if (fullscreenView) {
    return <></>
  }

  return <div className="header">
    <div style={{ position: "relative", display: "flex", alignItems: "center" }}>
      <img className="logo" src={logo} alt="logo" />
      {licenseEdition === LicenseProEdition && <div className="proTag">PRO</div>}
      {licenseEdition === LicenseEnterpriseEdition && <div className="proTag">ENTERPRISE</div>}
      {licenseEdition === LicenseOndemandEdition && <div className="proTag">ON-DEMAND</div>}
      {!licenseValid && <div className="extra" title={Moment(+licenseEnd)?.utc().format('MM/DD/YYYY, h:mm:ss.SSS A')}>Invalid License!</div>}
      <div className="nodeLimit">
        <InvalidLicenseModal
          licenseEdition={licenseEdition}
          licenseValid={licenseValid}
          licenseEnd={licenseEnd}
          licenseNodeLimit={licenseNodeLimit}
          licenseMaxNodeCount={licenseMaxNodeCount}
          licenseCurrentNodeCount={licenseCurrentNodeCount}
          licenseCapacity={licenseCapacity}
        />
        <p>
          Node limit: <span className={upgrade ? "upgrade" : "success"}>{licenseCurrentNodeCount}/{licenseNodeLimit}</span>
          {upgrade &&
            <Tooltip
              placement="bottom-start"
              title={
                <div className="obtainLicenseTooltipText">
                  <span>Important traffic is being discarded!</span>
                  <br/>
                  <span>Upgrade to capture all nodes&apos; traffic.</span>
                </div>
              }
              arrow
              variant="styled"
              arrowClass="styled"
              classes={{ tooltip: "obtainLicenseTooltip", arrow: "obtainLicenseTooltipArrow" }}
            >
              <ErrorIcon className="upgrade icon" sx={{ marginBottom: "1px" }}/>
            </Tooltip>
          }
        </p>
      </div>
      <div>
        {upgrade &&
          <Link href={LinkAdminConsole} target="_blank" rel="noopener noreferrer">
            <Button classes={{ root: 'upgradeButton' }} variant="outlined" size="small">
              <Typography classes={{ root: 'textUpgradeButton' }}>
                Upgrade
              </Typography>
            </Button>
          </Link>
        }
      </div>
    </div>
    <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
      <UI.DocsLink />
      <NavDivider />
      <UI.ScriptingButton />
      <NavDivider />
      <NavDropdown />
    </div>
  </div>;
}
