import Cookies from 'js-cookie'
import React from 'react'
import {
  SamlAuthType,
  SamlAuthCookieName
} from '../../../../consts'

import {
  Dialog,
  Grid,
} from '@mui/material'

import { DashboardBanner } from '../../DashboardBanner/DashboardBanner'
import { SamlSignInForm } from './SamlSignInForm/SamlSignInForm'

export const checkSamlEnabled = () => {
  return window.__RUNTIME_CONFIG__.REACT_APP_AUTH_TYPE === SamlAuthType
}

export const checkSamlAuth = () => Cookies.get(SamlAuthCookieName) !== undefined

export const samlLogout = () => {
  window.location.href = '/saml/logout'
}

export const triggerSamlAuthPath = '/records'

const muiDialogPaperPropVals = {
  style: {
    border: 0,
    borderRadius: 0,
    marginTop: 0,
    overflow: 'hidden',
    height: '100vh'
  }
}

export const SamlAuth: React.FC = () => {
  const [showAuthDialog, setShowAuthDialog] = React.useState(false)

  const handleCloseAuthDialog = () => setShowAuthDialog(false)

  React.useLayoutEffect(() => {
    if (window.location.pathname === triggerSamlAuthPath) {
      window.history.replaceState({}, '', '/');
    }

    if (checkSamlEnabled()) {
      setShowAuthDialog(!checkSamlAuth())
    }
  }, [])

  return (
    <Dialog
      fullScreen
      disableEscapeKeyDown
      open={showAuthDialog}
      onClose={handleCloseAuthDialog}
      PaperProps={muiDialogPaperPropVals}
    >
      <Grid container wrap='nowrap' sx={{ height: '100%' }}>
        <Grid item xs={5}>
          <SamlSignInForm />
        </Grid>
        <Grid item xs={7}>
          <DashboardBanner />
        </Grid>
      </Grid>
    </Dialog>
  )
}
