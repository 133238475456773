import './App.sass';
import React, { useEffect, useState } from "react";
import { Header } from "./components/Header/Header";
import { TrafficPage } from "./components/Pages/TrafficPage/TrafficPage";
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material';
import { useRecoilState, useSetRecoilState } from "recoil";
import scriptingModalOpenAtom from "./recoil/scriptingModalOpen";
import jobsModalOpenAtom from "./recoil/jobsModalOpen";
import { ScriptingModal } from './components/modals/ScriptingModal/ScriptingModal';
import { JobsModal } from './components/modals/JobsModal/JobsModal';
import { HttpStatusNodeLimitOverflow, HubBaseUrl } from "./consts";
import { toast } from "react-toastify";

import fetchIntercept from 'fetch-intercept'
import trafficPlaybackAtom, { TRAFFIC_PLAYBACK_STOPPED, TRAFFIC_PLAYBACK_OK } from './recoil/trafficPlayback/atom'
import nodeLimitOverflowAtom from './recoil/nodeLimitOverflow';

const nonLicenseRoutes = ["/license"]

const checkForNonLicenseRoute = (response: Response) => {
  return nonLicenseRoutes.findIndex(nonLicenseRoute => response.url.includes(nonLicenseRoute)) !== -1
}

import * as UI from "./components/UI";
import { checkAuth, checkAuthEnabled } from './components/UI/Auth/Auth';

const App: React.FC = () => {
  const setCapturePlayback = useSetRecoilState(trafficPlaybackAtom);
  const setNodeLimitOverflow = useSetRecoilState(nodeLimitOverflowAtom)

  fetchIntercept.register({
    response: function (response) {
      if (response.url.includes(window.location.hostname)) {
        if (response.status === HttpStatusNodeLimitOverflow) {
          setCapturePlayback(TRAFFIC_PLAYBACK_STOPPED)
          setNodeLimitOverflow(true)
        } else if (response.ok && !checkForNonLicenseRoute(response)) {
          setCapturePlayback(TRAFFIC_PLAYBACK_OK)
        }
      }
      return response
    }
  })
  const [scriptingModalOpen, setScriptingModalOpen] = useRecoilState(scriptingModalOpenAtom);
  const [jobsModalOpen, setJobsModalOpen] = useRecoilState(jobsModalOpenAtom);

  const [licenseEdition, setLicenseEdition] = useState("community");
  const [licenseValid, setLicenseValid] = useState(true);
  const [licenseEnd, setLicenseEnd] = useState(new Date().getTime());
  const [licenseMaxNodeCount, setLicenseMaxNodeCount] = useState(0);
  const [licenseCurrentNodeCount, setLicenseCurrentNodeCount] = useState(0);
  const [licenseNodeLimit, setLicenseNodeLimit] = useState(0);
  const [licenseCapacity, setLicenseCapacity] = useState(0);

  const getLicense = () => {
    fetch(`${HubBaseUrl}/license`)
      .then(response => response.ok ? response : response.text().then(err => Promise.reject(err)))
      .then(response => response.json())
      .then(data => {
        setLicenseEdition(data.doc.edition);
        setLicenseValid(data.valid);
        setLicenseEnd(data.doc.end);
        setLicenseMaxNodeCount(data.maxNodeCount);
        setLicenseCurrentNodeCount(data.currentNodeCount);
        setLicenseNodeLimit(data.doc.nodes);
        setLicenseCapacity(data.doc.capacity);
      })
      .catch(err => {
        console.error(err);
        toast.error(err.toString(), {
          theme: "colored"
        });
      });
  };

  useEffect(() => {
    if (checkAuthEnabled() && !checkAuth()) {
      return
    }

    getLicense()
  }, [])

  const renderApp = () => {
    if (checkAuthEnabled() && !checkAuth()) {
      return
    }

    return (
      <div className="kubesharkApp">
        <Header
          licenseEdition={licenseEdition}
          licenseValid={licenseValid}
          licenseEnd={licenseEnd}
          licenseMaxNodeCount={licenseMaxNodeCount}
          licenseCurrentNodeCount={licenseCurrentNodeCount}
          licenseNodeLimit={licenseNodeLimit}
          licenseCapacity={licenseCapacity}
        />
        <TrafficPage getLicense={getLicense} />
        {scriptingModalOpen && <ScriptingModal
          isOpen={scriptingModalOpen}
          onClose={() => setScriptingModalOpen(false)}
        />}
        {jobsModalOpen && <JobsModal
          isOpen={jobsModalOpen}
          onClose={() => setJobsModalOpen(false)}
        />}
      </div>
    )
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={createTheme(({}))}>
        <UI.Auth />
        {renderApp()}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
