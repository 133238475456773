import { Tooltip as MUITooltip, Fade, TooltipProps as MUITooltipProps } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";

export interface TooltipProps extends MUITooltipProps {
  variant?: 'default' | 'wide' | 'fit' | 'styled';
  arrowClass?: 'styled' | "";
  isSimple?: boolean;
  classes?: unknown;
}

export type TooltipPlacement = 'bottom-end' | 'bottom-start' | 'bottom' | 'left-end' | 'left-start' | 'left' | 'right-end' | 'right-start' | 'right' | 'top-end' | 'top-start' | 'top';

const styles = {
  default: {
    maxWidth: 300
  },
  wide: {
    maxWidth: 700
  },
  fit: {
    maxWidth: '100%'
  },
  styled: {
    border: '1px',
    borderRadius: '2px',
    padding: '8px',
    color: '#0C0B1A',
    maxWidth: 469,
    backgroundColor: '#BCCEFD',
    boxShadow: '0px 10px 30px 0px #8F9BB280',
    fontFamily: 'Source Sans Pro',
    fontSize: 11,
    fontWeight: 400,
    lineHeight: '14px',
    letterSpacing: '0.03em',
  },
  customArrow: {
    color: '#BCCEFD'
  }
};

const useStyles = makeStyles(() => styles);

const Tooltip: React.FC<TooltipProps> = (props) => {

  const { isSimple, ..._props } = props;

  const classes = useStyles(props.variant);

  const variant = props.variant || 'default';

  const backgroundClass = isSimple ? "" : "noBackground"

  const customClasses = { tooltip: `${backgroundClass} ` + classes[variant],
    arrow: undefined
  }

  if (props.arrowClass == 'styled') {
    customClasses.arrow = classes.customArrow
  }

  return (
    <MUITooltip
      classes={customClasses}
      enterDelay={200}
      TransitionComponent={Fade}
      {..._props}
    >
      {props.children || <div />}
    </MUITooltip>
  );
};

export default Tooltip;
