enum NodeTypes {
  Name = 'name',
  Namespace = 'namespace',
  Pod = 'pod',
  EndpointSlice = 'endpointSlice',
  Service = 'service',
  WorkerNode = 'node'
}

export default NodeTypes
