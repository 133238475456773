import { atom } from "recoil"

export const TRAFFIC_PLAYBACK_CHECKING = "CHECKING"
export const TRAFFIC_PLAYBACK_OK = "OK"
export const TRAFFIC_PLAYBACK_STOPPED = "STOPPED"

const trafficPlaybackAtom = atom({
  key: "trafficPlaybackAtom",
  default: TRAFFIC_PLAYBACK_CHECKING
})

export default trafficPlaybackAtom;
