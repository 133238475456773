import React from 'react'
import { useRecoilState } from 'recoil';

import Moment from "moment";
import { useInterval } from '../../../helpers/interval';

import styles from "./TrafficStatsFooter.module.sass";

import entriesCapturedAtom from "../../../recoil/entriesCaptured";
import sizeCapturedAtom from "../../../recoil/sizeCaptured";

export const TrafficStatsFooter: React.FC = () => {
  const [timeNow, setTimeNow] = React.useState(new Date());

  const [entriesCaptured] = useRecoilState(entriesCapturedAtom);
  const [sizeCaptured] = useRecoilState(sizeCapturedAtom);

  useInterval(async () => {
    setTimeNow(new Date());
  }, 1000, true);

  return (
    <div className={styles.TrafficStatsFooter}>
      <div>
        Showing <b id='item-count'>{entriesCaptured}</b> items from a total of{' '}
        <b id='total-tcp-streams'>{sizeCaptured}</b> traffic capture
      </div>
      <div>
        UTC:
        <span
          style={{
            marginLeft: 5,
            marginRight: 5,
            fontWeight: 600
          }}
        >
          {Moment(timeNow).utc().format('MM/DD/YYYY, h:mm:ss.SSS A')}
        </span>
      </div>
    </div>
  )
}
