import React from 'react'

import variables from '../../../variables.module.scss';

import {
  TableHead as MuiTableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableSortLabel,
  Box
} from '@mui/material'

import { visuallyHidden } from '@mui/utils';

export interface HeadCell {
  id: string;
  align?: 'left' | 'center' | 'right';
  disablePadding?: boolean;
  disableSorting?: boolean;
  label: string;
}

interface TableHeadProps {
  headCells: HeadCell[]
  numSelected: number
  onRequestSort: (event, property) => void
  onSelectAllClick: (event) => void
  order: 'asc' | 'desc'
  orderBy: string
  rowCount: number
}

export const TableHead: React.FC<TableHeadProps> = (
  props
) => {
  const {
    headCells,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort
  } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  const getTableSortLabel = (headCell: HeadCell, orderBy: string) => {
    if (headCell.disableSorting) {
      return headCell.label
    }

    return (
      <TableSortLabel
        active={orderBy === headCell.id}
        direction={orderBy === headCell.id ? order : 'asc'}
        onClick={createSortHandler(headCell.id)}
      >
        {headCell.label}
        {orderBy === headCell.id ? (
          <Box component='span' sx={visuallyHidden}>
            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
          </Box>
        ) : null}
      </TableSortLabel>
    )
  }

  return (
    <MuiTableHead>
      <TableRow>
        <TableCell padding='checkbox'>
          <Checkbox
            color='primary'
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts'
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell?.align || 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ padding: "5px", fontFamily: variables.textFontFamily, fontWeight: 600 }}
          >
            {getTableSortLabel(headCell, orderBy)}
          </TableCell>
        ))}
      </TableRow>
    </MuiTableHead>
  )
}
