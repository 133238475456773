import React from 'react'

import { Box, Typography, Divider } from '@mui/material'

import styles from './DashboardBanner.module.sass'

import variables from '../../../variables.module.scss'
import logoBackdrop from '../../../assets/logoBackdrop.svg'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

export const DashboardBanner: React.FC = () => {
  return (
    <Box className={styles.DashboardBanner}>
      <Typography
        variant='h2'
        fontFamily={variables.textFontFamily}
        fontWeight={700}
        color={variables.lightBlueColor}
        style={{ width: '80%' }}
      >
        Accelerate Your Root Cause Analysis!
      </Typography>
      <Typography
        fontFamily={variables.textFontFamily}
        color={variables.mainBackgroundColor}
        style={{ width: '80%' }}
      >
        See with your own eyes what&apos;s happening in all corners of your
        Kubernetes clusters. <br />
        Enjoy real-time, cluster-wide, identity-aware, protocol-level visibility
        into API traffic. <br />
        Quickly recover from production incidents, fix bugs and performance
        issues faster.
      </Typography>
      <Divider sx={{ width: '50%', backgroundColor: '#6c87fa' }} />
      <Typography
        variant='h4'
        fontFamily={variables.textFontFamily}
        fontWeight={700}
        color={variables.lightBlueColor}
      >
        Dashboard
      </Typography>
      <ul className={styles.DashboardFeaturesList}>
        <li>
          <CheckCircleIcon htmlColor='#ffffff' />
          <Typography
            fontFamily={variables.textFontFamily}
            fontWeight={600}
            color={variables.mainBackgroundColor}
          >
            Capture & Monitor I/O traffic across <br />
            containers, pods, namespaces, nodes and clusters
          </Typography>
        </li>
        <li>
          <CheckCircleIcon htmlColor='#ffffff' />
          <Typography
            fontFamily={variables.textFontFamily}
            fontWeight={600}
            color={variables.mainBackgroundColor}
          >
            Real-time Kubernetes protocol-level visibility
          </Typography>
        </li>
        <li>
          <CheckCircleIcon htmlColor='#ffffff' />
          <Typography
            fontFamily={variables.textFontFamily}
            fontWeight={600}
            color={variables.mainBackgroundColor}
          >
            Deep Network Observability
          </Typography>
        </li>
        <li>
          <CheckCircleIcon htmlColor='#ffffff' />
          <Typography
            fontFamily={variables.textFontFamily}
            fontWeight={600}
            color={variables.mainBackgroundColor}
          >
            Traffic Recording & Offline Analysis
          </Typography>
        </li>
        <li>
          <CheckCircleIcon htmlColor='#ffffff' />
          <Typography
            fontFamily={variables.textFontFamily}
            fontWeight={600}
            color={variables.mainBackgroundColor}
          >
            Incident Detection & Response
          </Typography>
        </li>
      </ul>
      <img src={logoBackdrop} className={styles.LogoBackdrop} alt='Logo backdrop' />
    </Box>
  )
}
