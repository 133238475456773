import React, { FC, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import styles from './Filters.module.sass';
import { Button, Grid, Modal, Box, Typography, Backdrop, Fade, Divider, debounce, Link, IconButton, Badge, ClickAwayListener, Tooltip } from "@mui/material";
import CodeEditor from '@uiw/react-textarea-code-editor';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { SyntaxHighlighter } from "../UI/SyntaxHighlighter";
import filterUIExample1 from "./assets/filter-ui-example-1.png"
import filterUIExample2 from "./assets/filter-ui-example-2.png"
import useKeyPress from "../../hooks/useKeyPress"
import shortcutsKeyboard from "../../configs/shortcutsKeyboard"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import queryAtom from "../../recoil/query";
import queryBuildAtom from "../../recoil/queryBuild";
import queryBackgroundColorAtom from "../../recoil/queryBackgroundColor";
import { toast } from "react-toastify";
import { HubBaseUrl, ColorGreen, ColorRed, ColorWhite } from "../../consts";
import { useNavigate, useLocation } from 'react-router-dom';
import { CreateRecordingDialog } from "../CreateRecordingDialog/CreateRecordingDialog";
import variables from '../../variables.module.scss';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import SelectAllOutlinedIcon from '@mui/icons-material/SelectAllOutlined';
import ViewInArRoundedIcon from '@mui/icons-material/ViewInArRounded';
import './Filters.sass';
import { AutoAppliedFilter } from "./AutoAppliedFilter/AutoAppliedFilter";
import appliedPodRegexAtom from "../../recoil/appliedPodRegex/atom";
import appliedPodNamespacesAtom from "../../recoil/appliedPodNamespaces/atom";
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';

interface CodeEditorWrap {
  reopenConnection?: () => void;
  onQueryChange?: (q: string) => void
  onValidationChanged?: (event: OnQueryChange) => void
  hideAutoAppliedFilters?: boolean
}

export const Filters: React.FC<CodeEditorWrap> = ({ reopenConnection, onQueryChange }) => {
  return <div className={styles.container}>
    <QueryForm
      reopenConnection={reopenConnection}
      onQueryChange={onQueryChange}
    />
  </div>;
};

type OnQueryChange = { valid: boolean, message: string, query: string }

export const modalStyle = {
  position: 'absolute',
  top: '10%',
  left: '50%',
  transform: 'translate(-50%, 0%)',
  width: '80vw',
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 24,
  outline: "none",
  p: 4,
  color: '#000',
};

interface AutoAppliedFiltersProps {
  show: boolean
}

const AutoAppliedFilters: FC<AutoAppliedFiltersProps> = ({ show }) => {
  const [globalFilter, setGlobalFilter] = useState("")
  const [samlFilter, setSamlFilter] = useState("")

  const appliedPodRegex = useRecoilValue(appliedPodRegexAtom)
  const appliedPodNamespaces = useRecoilValue(appliedPodNamespacesAtom)

  const fetchQueryFilters = () => {
    fetch(`${HubBaseUrl}/query/filters`)
      .then(response => response.ok ? response : response.text().then(err => Promise.reject(err)))
      .then(response => response.json())
      .then(data => {
        setGlobalFilter(data.global.trim())
        setSamlFilter(data.saml.trim())
      })
      .catch(err => {
        console.error(err);
        toast.error(err.toString(), {
          theme: "colored"
        });
      });
  }

  useEffect(() => {
    fetchQueryFilters()
  }, [])

  if (!show) {
    return <></>
  }

  return (
    <Box position='absolute' bottom={0} right={0} width='100%' display='flex' alignItems='center' gap='5px' height='32px' borderRadius='2px' bgcolor={variables.mainBackgroundColor}>
      <Typography
        variant='body2'
        fontSize={12}
        fontFamily='Roboto'
        color={variables.secondaryFontColor}
        fontWeight={500}
        sx={{ marginLeft: '8px' }}
      >
        Auto-applied:
      </Typography>
      {samlFilter.length > 0 && <AutoAppliedFilter
        name='SAML role'
        icon={<GroupRoundedIcon fontSize='small' htmlColor={variables.blueColor} sx={{ transition: 'all 0.2s' }} />}
        filter={samlFilter}
      />}
      {globalFilter.length > 0 && <AutoAppliedFilter
        name='Global filter'
        icon={<SelectAllOutlinedIcon fontSize='small' htmlColor={variables.blueColor} sx={{ transition: 'all 0.2s' }} />}
        filter={globalFilter}
      />}
      <AutoAppliedFilter
        name='Pod targeting'
        icon={<ViewInArRoundedIcon fontSize='small' htmlColor={variables.blueColor} sx={{ transition: 'all 0.2s' }} />}
        filter={`"regex": ${appliedPodRegex.length > 0 ? `(${appliedPodRegex})` : 'all'}, "namespaces": ${appliedPodNamespaces.length > 0 ? `[${appliedPodNamespaces.join(', ')}]` : 'all'}`}
      />
    </Box>
  )
}

const autoAppliedFiltersPresentedKey = 'kubeshark.autoAppliedFiltersPresented'

export const CodeEditorWrap: FC<CodeEditorWrap> = ({ onQueryChange, onValidationChanged, hideAutoAppliedFilters }) => {
  const [queryBackgroundColor, setQueryBackgroundColor] = useRecoilState(queryBackgroundColorAtom);

  const queryBuild = useRecoilValue(queryBuildAtom);

  const [showAppliedFilters, setShowAppliedFilters] = useState(localStorage.getItem(autoAppliedFiltersPresentedKey) === null)

  useLayoutEffect(() => {
    const timeout = setTimeout(() => {
      setShowAppliedFilters(false)
    }, 3000)

    localStorage.setItem(autoAppliedFiltersPresentedKey, 'true')

    return () => clearTimeout(timeout)
  }, [])

  const handleQueryChange = useMemo(
    () =>
      debounce(async (query: string) => {
        if (!query) {
          setQueryBackgroundColor(ColorWhite);
          onValidationChanged && onValidationChanged({ query: query, message: "", valid: true });
        } else {
          fetch(`${HubBaseUrl}/query/validate?q=${encodeURIComponent(query)}`)
            .then(response => response.ok ? response : response.text().then(err => Promise.reject(err)))
            .then(response => response.json())
            .then(data => {
              if (data.valid) {
                setQueryBackgroundColor(ColorGreen);
              } else {
                setQueryBackgroundColor(ColorRed);
              }
              onValidationChanged && onValidationChanged({ query: query, message: data.message, valid: data.valid })
            })
            .catch(err => {
              console.error(err);
              toast.error(err.toString(), {
                theme: "colored"
              });
            });
        }
      }, 100),
    [onValidationChanged]
  ) as (query: string) => void;

  useEffect(() => {
    handleQueryChange(queryBuild);
  }, [queryBuild, handleQueryChange]);

  return (
    <ClickAwayListener onClickAway={() => setShowAppliedFilters(false)}>
      <Box position='relative' width='100%' display='flex' borderRadius='2px' className={`queryInputContainer${hideAutoAppliedFilters ? '--noAutoAppliedFilters' : ''}`}>
        <CodeEditor
          autoFocus
          value={queryBuild}
          language="py"
          placeholder="Kubeshark Filter Syntax"
          onChange={(event) => onQueryChange(event.target.value)}
          padding={8}
          style={{
            width: '100%',
            paddingBottom: showAppliedFilters ? '30px' : 0,
            paddingRight: hideAutoAppliedFilters ? 'unset' : '36px',
            transition: 'padding-bottom 0.2s',
            fontSize: 14,
            borderRadius: '2px',
            backgroundColor: `${queryBackgroundColor}`,
            fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
          }}
        />
        {!hideAutoAppliedFilters && <>
          <Tooltip title={`${showAppliedFilters ? 'Hide' : 'Show'} auto-applied filters`} placement='top' arrow>
            <IconButton
              size='small'
              sx={{
                position: 'absolute',
                right: 0,
                height: '32px',
                width: '32px',
                borderRadius: '2px',
                backgroundColor: variables.mainBackgroundColor
              }}
              onClick={() => setShowAppliedFilters(!showAppliedFilters)}
            >
              <Badge variant='dot' color='primary' invisible={showAppliedFilters}>
                <FilterListRoundedIcon fontSize='small' htmlColor={showAppliedFilters ? variables.blueColor : variables.grayColor} />
              </Badge>
            </IconButton>
          </Tooltip>
          <AutoAppliedFilters show={showAppliedFilters} />
        </>}
      </Box>
    </ClickAwayListener>
  )
}

export const QueryForm: React.FC<CodeEditorWrap> = ({ reopenConnection, onQueryChange, onValidationChanged }) => {

  const formRef = useRef<HTMLFormElement>(null);

  const [openModal, setOpenModal] = useState(false);

  const queryBuild = useRecoilValue(queryBuildAtom);
  const setQuery = useSetRecoilState(queryAtom);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = (e) => {
    setQuery(queryBuild);
    navigate({ pathname: location.pathname, search: `q=${encodeURIComponent(queryBuild)}` });
    reopenConnection();
    e.preventDefault();
  }

  useKeyPress(shortcutsKeyboard.ctrlEnter, handleSubmit, formRef.current);

  return <React.Fragment>
    <form
      ref={formRef}
      onSubmit={handleSubmit}
      style={{
        width: '100%',
      }}
    >
      <Grid container spacing={2} wrap="nowrap">
        <Grid
          item
          xs={12}
          style={{
            maxHeight: '25vh',
            overflowY: 'auto',
          }}
        >
          <label>
            <CodeEditorWrap onQueryChange={onQueryChange} onValidationChanged={onValidationChanged} />
          </label>
        </Grid>
        <Grid item xs="auto">
          <Button
            type="submit"
            variant="contained"
            className={`${styles.bigButton}`}
          >
            Apply
          </Button>
          <Button
            title="Open Filtering Guide (Cheatsheet)"
            variant="contained"
            color="primary"
            className={`${styles.smallButton}`}
            onClick={handleOpenModal}
          >
            <MenuBookIcon fontSize="inherit"></MenuBookIcon>
          </Button>
          <CreateRecordingDialog buttonClassName={`${styles.smallButton}`} />
        </Grid>
      </Grid>
    </form>

    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModal}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      style={{ overflow: 'auto' }}
    >
      <Fade in={openModal}>
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h5" component="h2" style={{ textAlign: 'center' }}>
            Filtering Guide (Cheatsheet)
          </Typography>
          <Typography component={'span'} id="modal-modal-description">
            <p>Kubeshark has a rich filtering syntax that let&apos;s you query the results both flexibly and efficiently.</p>
            <p>Here are some examples that you can try;</p>
          </Typography>
          <Grid container>
            <Grid item xs style={{ margin: "10px" }}>
              <Typography id="modal-modal-description">
                This is a simple query that matches to HTTP packets with request path &quot;catalogue&quot;:
              </Typography>
              <SyntaxHighlighter
                showLineNumbers={false}
                code={`http and request.path == "/catalogue"`}
                language="python"
              />
              <Typography id="modal-modal-description">
                The same query can be negated for HTTP path and written like this:
              </Typography>
              <SyntaxHighlighter
                showLineNumbers={false}
                code={`http and request.path != "/catalogue"`}
                language="python"
              />
              <Typography id="modal-modal-description">
                The syntax supports regular expressions. Here is a query that matches the HTTP requests that send JSON to a server:
              </Typography>
              <SyntaxHighlighter
                showLineNumbers={false}
                code={`http and request.headers["Accept"] == r"application/json.*"`}
                language="python"
              />
              <Typography id="modal-modal-description">
                Here is another query that matches HTTP responses with status code 4xx:
              </Typography>
              <SyntaxHighlighter
                showLineNumbers={false}
                code={`http and response.status == r"4.*"`}
                language="python"
              />
              <Typography id="modal-modal-description">
                The same exact query can be as integer comparison:
              </Typography>
              <SyntaxHighlighter
                showLineNumbers={false}
                code={`http and response.status >= 400`}
                language="python"
              />
              <Typography id="modal-modal-description">
                The results can be queried based on their timestamps:
              </Typography>
              <SyntaxHighlighter
                showLineNumbers={false}
                code={`timestamp < datetime("10/28/2021, 9:13:02.905 PM")`}
                language="python"
              />
            </Grid>
            <Divider className={styles.divider1} orientation="vertical" flexItem />
            <Grid item xs style={{ margin: "10px" }}>
              <Typography id="modal-modal-description">
                Since Kubeshark supports various protocols like gRPC, AMQP, Kafka and Redis. It&apos;s possible to write complex queries that match multiple protocols like this:
              </Typography>
              <SyntaxHighlighter
                showLineNumbers={false}
                code={`(http and request.method == "PUT") or (amqp and request.queue.startsWith("test"))\n or (kafka and response.payload.errorCode == 2) or (redis and request.key == "example")\n or (grpc and request.headers[":path"] == r".*foo.*")`}
                language="python"
              />
              <Typography id="modal-modal-description">
                By clicking the plus icon that appears beside the queryable UI elements on hovering in both left-pane and right-pane, you can automatically select a field and update the query:
              </Typography>
              <img
                src={filterUIExample1}
                width={600}
                alt="Clicking to UI elements (left-pane)"
                title="Clicking to UI elements (left-pane)"
              />
              <Typography id="modal-modal-description">
                Such that; clicking this icon in left-pane, would append the query below:
              </Typography>
              <SyntaxHighlighter
                showLineNumbers={false}
                code={`and dst.name == "carts" and dst.namespace == "sock-shop"`}
                language="python"
              />
              <Typography id="modal-modal-description">
                Another queriable UI element example, this time from the right-pane:
              </Typography>
              <img
                src={filterUIExample2}
                width={300}
                alt="Clicking to UI elements (right-pane)"
                title="Clicking to UI elements (right-pane)"
              />
              <Typography id="modal-modal-description">
                A query that compares one selector to another is also a valid query:
              </Typography>
              <SyntaxHighlighter
                showLineNumbers={false}
                code={`http and (request.query["x"] == response.headers["y"]\n or response.content.text.contains(request.query["x"]))`}
                language="python"
              />
            </Grid>
            <Divider className={styles.divider2} orientation="vertical" flexItem />
            <Grid item xs style={{ margin: "10px" }}>
              <Typography id="modal-modal-description">
                There are a few helper methods included the in the filter language* to help building queries more easily.
              </Typography>
              <br></br>
              <Typography id="modal-modal-description">
                true if the given selector&apos;s value starts with (similarly <code style={{ fontSize: "14px" }}>endsWith</code>, <code style={{ fontSize: "14px" }}>contains</code>) the string:
              </Typography>
              <SyntaxHighlighter
                showLineNumbers={false}
                code={`request.path.startsWith("something")`}
                language="python"
              />
              <Typography id="modal-modal-description">
                a field that contains a JSON encoded string can be filtered based a JSONPath:
              </Typography>
              <SyntaxHighlighter
                showLineNumbers={false}
                code={`response.content.text.json().some.path == "somevalue"`}
                language="python"
              />
              <Typography id="modal-modal-description">
                fields that contain sensitive information can be redacted:
              </Typography>
              <SyntaxHighlighter
                showLineNumbers={false}
                code={`and redact("request.path", "src.name")`}
                language="python"
              />
              <Typography id="modal-modal-description">
                returns the UNIX timestamp which is the equivalent of the time that&apos;s provided by the string. Invalid input evaluates to false:
              </Typography>
              <SyntaxHighlighter
                showLineNumbers={false}
                code={`timestamp >= datetime("10/19/2021, 6:29:02.593 PM")`}
                language="python"
              />
              <Typography id="modal-modal-description">
                limits the number of records that are streamed back as a result of a query. Always evaluates to true:
              </Typography>
              <SyntaxHighlighter
                showLineNumbers={false}
                code={`and limit(100)`}
                language="python"
              />
            </Grid>
          </Grid>
          <br></br>
          <Typography id="modal-modal-description" style={{ fontSize: 12, fontStyle: 'italic' }}>
            Please refer to <Link href="https://docs.kubeshark.co/en/filtering#kfl-syntax-reference" underline="hover" target="_blank"><b>KFL Syntax Reference</b></Link> for more information.&nbsp;
            <a className="kbc-button kbc-button-xxs">Ctrl</a> + <a className="kbc-button kbc-button-xxs">Enter</a> keyboard shortcut applies the filter.
          </Typography>
        </Box>
      </Fade>
    </Modal>
  </React.Fragment>
}
