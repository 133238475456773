import React from 'react'
import { SamlAuth, checkSamlAuth, samlLogout } from './SamlAuth/SamlAuth'
import { SamlAuthType } from '../../../consts'

export const checkAuthEnabled = () => {
  return (
    window.__RUNTIME_CONFIG__.REACT_APP_AUTH_ENABLED &&
    window.__RUNTIME_CONFIG__.REACT_APP_AUTH_ENABLED != 'false'
  )
}

const getAuthType = () => {
  return window.__RUNTIME_CONFIG__.REACT_APP_AUTH_TYPE
}

export const checkAuth = (): boolean => {
  if (!checkAuthEnabled()) {
    return
  }

  const authType = getAuthType()

  switch (authType) {
  case SamlAuthType:
    return checkSamlAuth()
  default:
    console.error('Unsupported auth type:', authType)
  }
}

export const logout = () => {
  if (!checkAuthEnabled()) {
    return
  }

  const authType = getAuthType()

  switch (authType) {
  case SamlAuthType:
    samlLogout()
    break
  default:
    console.error('Unsupported auth type:', authType)
  }
}

export const Auth: React.FC = () => {
  if (!checkAuthEnabled()) {
    return <></>
  }

  const authType = getAuthType()

  switch (authType) {
  case SamlAuthType:
    return <SamlAuth />
  default:
    console.error('Unsupported auth type:', authType)
    return <></>
  }
}
