import React, { ReactElement, useEffect, useState } from 'react'
import { Modal, Box, Typography, Button, IconButton, Link } from '@mui/material'
import { useRecoilValue } from 'recoil'
import nodeLimitOverflowAtom from '../../../recoil/nodeLimitOverflow';
import Moment from 'moment';

import styles from './InvalidLicenseModal.module.sass'
import variables from '../../../variables.module.scss'
import KeyIcon from '@mui/icons-material/Key';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ErrorIcon from '@mui/icons-material/Error';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import { LicenseCommunityEdition, LinkAdminConsole, LinkContactUs } from '../../../consts'
import pluralize from 'pluralize';

interface InvalidLicenseModalProps {
  licenseEdition: string;
  licenseValid: boolean;
  licenseEnd: number;
  licenseNodeLimit: number;
  licenseMaxNodeCount: number;
  licenseCurrentNodeCount: number;
  licenseCapacity: number;
}

export const InvalidLicenseModal: React.FC<InvalidLicenseModalProps> = ({ licenseEdition, licenseValid, licenseEnd, licenseNodeLimit, licenseMaxNodeCount, licenseCurrentNodeCount, licenseCapacity }) => {
  const nodeLimitOverflow = useRecoilValue(nodeLimitOverflowAtom)
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    setModalOpen(!licenseValid)

    if (licenseValid && licenseNodeLimit > 0) {
      setModalOpen(nodeLimitOverflow)
    }
  }, [licenseValid, licenseNodeLimit, nodeLimitOverflow])

  const handleCloseModal = () => {
    if (!nodeLimitOverflow) {
      setModalOpen(false)
    }
  }

  const getFormattedLicenseEndDate = () => {
    return Moment(+licenseEnd)?.utc().format('MMMM Do, YYYY')
  }

  let descriptionElement: ReactElement = null

  descriptionElement = <>
    <Typography variant='body1' fontFamily={variables.textFontFamily} color={variables.fontColor}>
      Oops! It seems something went wrong with your license, please <Link href={LinkContactUs} target='_blank' underline='hover' fontWeight={700} color={variables.blueColor}>contact us</Link> for a swift resolution.
    </Typography>
  </>

  if ((licenseCurrentNodeCount>licenseMaxNodeCount) && (licenseEdition === LicenseCommunityEdition)) {
    descriptionElement = <>
      <Typography variant='body1' fontFamily={variables.textFontFamily} color={variables.fontColor}>
        Oops! It seems you&apos;re using the <strong style={{ textTransform: 'uppercase' }}>{licenseEdition}</strong> edition, 
        which currently has a limit of only <strong>{licenseMaxNodeCount}</strong> {pluralize('node', licenseMaxNodeCount)}.<br/> 
        Not to worry, a complimentary license with support for <strong>20</strong> nodes is available at no cost, right now.<br/><br/>
        * If you believe this message is in error, please <Link href={LinkContactUs} target='_blank' underline='hover' fontWeight={700} color={variables.blueColor}>contact us</Link> for a swift resolution.
      </Typography>
    </>
  }


  if ((licenseEdition != LicenseCommunityEdition) && (licenseCapacity<0)) {
    descriptionElement = <>
      <Typography variant='body1' fontFamily={variables.textFontFamily} color={variables.fontColor}>
        Oops! It appears you&apos;ve reached your API call capacity limit. Fortunately, this is an easy fix.<br />
        In any case, your capacity will reset on <strong style={{ color: variables.upgradeColor }}>{getFormattedLicenseEndDate()}</strong>.<br/><br/>
        * If you believe this message is in error, please <Link href={LinkContactUs} target='_blank' underline='hover' fontWeight={700} color={variables.blueColor}>contact us</Link> for a swift resolution.
      </Typography>
    </>
  }

  if ((licenseEdition != LicenseCommunityEdition) && (licenseCurrentNodeCount>licenseNodeLimit)) {
    descriptionElement = <>
      <Typography variant='body1' fontFamily={variables.textFontFamily} color={variables.fontColor}>
        Oops! It seems you&apos;re using the <strong style={{ textTransform: 'uppercase' }}>{licenseEdition}</strong> edition, 
        which currently has a limit of only <strong>{licenseNodeLimit}</strong> {pluralize('node', licenseNodeLimit)}.<br/>
        Fortunately, this is an easy fix. Please <Link href={LinkContactUs} target='_blank' underline='hover' fontWeight={700} color={variables.blueColor}>contact us</Link> to obtain the correct license.<br/><br/>
        * If you believe this message is in error, please <Link href={LinkContactUs} target='_blank' underline='hover' fontWeight={700} color={variables.blueColor}>contact us</Link> for a swift resolution.
      </Typography>
    </>
  }

  return (
    <Modal open={modalOpen} onClose={handleCloseModal}>
      <Box className={styles.InvalidLicenseModal}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <KeyIcon htmlColor={variables.lightBlueColor} sx={{ fontSize: '42px' }} />
            <ErrorIcon fontSize='medium' htmlColor={variables.upgradeColor} sx={{ position: 'absolute', bottom: "-3px", right: "-10px" }} />
          </Box>
          {!nodeLimitOverflow && <IconButton aria-label="delete" onClick={handleCloseModal}>
            <CloseRoundedIcon htmlColor={variables.grayColor} />
          </IconButton>}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
          <Typography variant='h3' fontFamily={variables.textFontFamily} fontWeight={700} color={variables.fontColor}>
            License Issue Detected!
          </Typography>
          <Typography variant='h6' fontFamily={variables.textFontFamily} fontWeight={600} color={variables.secondaryFontColor}>
          Kubeshark License Update Needed
          </Typography>
        </Box>
        <Typography variant='body1' fontFamily={variables.textFontFamily} color={variables.fontColor}>{descriptionElement}</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '15px' }}>
          <Button
            variant='contained'
            size='large'
            startIcon={<UpgradeIcon sx={{ marginRight: "5px" }} />}
            sx={{ backgroundColor: variables.blueColor, pl: "15px", pr: "15px" }}
            onClick={()=> window.open(LinkAdminConsole, "_blank")}
          >
            <Typography variant='body1' fontFamily={variables.textFontFamily} fontWeight={600} color='#FFFFFF'>
              Upgrade now
            </Typography>
          </Button>
          <Typography variant='body2' fontFamily={variables.textFontFamily} color={variables.secondaryFontColor}>
            You will be forwarded to Kubeshark Admin Console.
          </Typography>
        </Box>
      </Box>
    </Modal>
  )
}
