const HubBaseUrl = `${window.location.protocol !== "https:" ? "http" : "https"}://${window.location.host}/api`
const HubWsUrl = `${window.location.protocol !== "https:" ? "ws" : "wss"}://${window.location.host}/api/ws`
const HubScriptLogsWsUrl = `${window.location.protocol !== "https:" ? "ws" : "wss"}://${window.location.host}/api/scripts/logs`

const ColorGreen = "#d2fad2"
const ColorRed = "#fad6dc"
const ColorYellow = "#f6fad2"
const ColorWhite = "#ffffff"

const LinkDocs = "https://docs.kubeshark.co";
const LinkAdminConsole = "https://console.kubeshark.co/?origin=upgrade";
const LinkContactUs = "https://www.kubeshark.co/contact-us";

const LicenseCommunityEdition = "community";
const LicenseProEdition = "PRO";
const LicenseEnterpriseEdition = "ENTERPRISE";
const LicenseOndemandEdition = "ON-DEMAND";

const HttpStatusNodeLimitOverflow = 418

const SamlAuthType = 'saml'

const SamlAuthCookieName = 'saml_token'

export {
  HubBaseUrl,
  HubWsUrl,
  HubScriptLogsWsUrl,
  ColorGreen,
  ColorRed,
  ColorYellow,
  ColorWhite,
  LinkDocs,
  LinkAdminConsole,
  LinkContactUs,
  LicenseCommunityEdition,
  LicenseProEdition,
  LicenseEnterpriseEdition,
  LicenseOndemandEdition,
  HttpStatusNodeLimitOverflow,
  SamlAuthType,
  SamlAuthCookieName
}
