import * as React from 'react'
import Box from '@mui/material/Box'
import { CircularProgress, Table as MuiTable } from '@mui/material'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Checkbox from '@mui/material/Checkbox'
import { HeadCell, TableHead } from './TableHead'
import { TableToolbar } from './TableToolbar'

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

const rowsPerPageOptions = [5]

export interface BodyCell {
  headCellId: string
  align: 'left' | 'center' | 'right'
  render: 'checkbox' | ((data: unknown) => string | React.ReactNode)
}

interface TableProps {
  rows: unknown[],
  confirmDeletedEntries: (confirmed: React.SetStateAction<unknown[]>) => void
  dataFetched: boolean
  headCells: HeadCell[]
  bodyCells: BodyCell[]
  selectBy: string
  searchBy: string
}

export const Table: React.FC<TableProps> = ({ rows, confirmDeletedEntries, dataFetched, headCells, bodyCells, selectBy, searchBy }) => {
  const [order, setOrder] = React.useState<'asc' | 'desc'>('asc')
  const [orderBy, setOrderBy] = React.useState('recordingNameHeadCell')
  const [selected, setSelected] = React.useState([])
  const [selectedEntries, setSelectedEntries] = React.useState([])
  const [deleteSelectedSignal, setDeleteSelectedSignal] = React.useState(false)
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [emptyRows, setEmptyRows] = React.useState(0)

  const [searchQuery, setSearchQuery] = React.useState('')

  React.useEffect(() => {
    if (rows.length === 0 || selectedEntries.length === 0) {
      return
    }

    confirmDeletedEntries(selectedEntries)

    setSelected([])
    setSelectedEntries([])
  }, [deleteSelectedSignal])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = searchRows.map((n) => n[selectBy])
      setSelected(newSelected)
      setSelectedEntries(searchRows)
      return
    }
    setSelected([])
    setSelectedEntries([])
  }

  const handleSelected = (row, selectedArr, wholeRow = false) => {
    const selectedIndex = selectedArr.indexOf(wholeRow ? row : row[selectBy])
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedArr, wholeRow ? row : row[selectBy])
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedArr.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selectedArr.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedArr.slice(0, selectedIndex),
        selectedArr.slice(selectedIndex + 1)
      )
    }

    return newSelected
  }

  const handleClick = (event, row) => {
    const newSelected = handleSelected(row, selected)
    setSelected(newSelected)

    const newSelectedEntries = handleSelected(row, selectedEntries, true)
    setSelectedEntries(newSelectedEntries)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const isSelected = (id) => selected.indexOf(id) !== -1

  const searchRows = React.useMemo(() => {
    setPage(0)

    if (searchQuery.trim().length === 0) {
      return rows
    }

    return rows.filter((row) => {
      return row[searchBy].toLowerCase().includes(searchQuery.toLowerCase().trim())
    })
  }, [rows, searchQuery])

  const calculateEmptyRows = (baseRows, ignorePage = false) =>
    ignorePage || page > 0 ? Math.max(0, (1 + page) * rowsPerPageOptions[0] - baseRows.length) : 0

  React.useEffect(() => {
    setEmptyRows(calculateEmptyRows(searchQuery.trim() !== "" ? searchRows : rows, searchQuery.trim() !== ""))
  }, [page, searchRows])

  const visibleRows = React.useMemo(() => {
    return stableSort(searchRows, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    )
  }, [order, orderBy, page, rowsPerPage, searchQuery, searchRows])

  if (rows.length === 0) {
    return (<></>)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <TableToolbar
        numSelected={selected.length}
        searchBy={searchBy}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        setDeleteSelectedSignal={setDeleteSelectedSignal}
      />
      <TableContainer sx={{ mt: '5px' }}>
        <MuiTable
          stickyHeader
          aria-labelledby='tableTitle'
          size={'medium'}
        >
          <TableHead
            headCells={headCells}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={searchRows.length}
          />
          <TableBody>
            {visibleRows.map((row) => {
              const isItemSelected = isSelected(row[selectBy])

              return (
                <TableRow
                  hover
                  onClick={(event) => handleClick(event, row)}
                  role='checkbox'
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row[selectBy]}
                  selected={isItemSelected}
                  sx={{ cursor: 'pointer' }}
                >
                  {bodyCells.map(cell => {
                    if (cell.render === 'checkbox') {
                      return (
                        <TableCell key={cell.headCellId} padding='checkbox'>
                          <Checkbox
                            color='primary'
                            checked={isItemSelected}
                          />
                        </TableCell>
                      )
                    }

                    return (
                      <TableCell key={cell.headCellId} align={cell.align} sx={{ padding: "5px" }}>
                        {cell.render(row)}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
            {!dataFetched && visibleRows.length === 0 && (
              <>
                <TableRow>
                  <TableCell colSpan={6} align='center' sx={{ border: 0 }}>Fetching your entries...</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={6} align='center' sx={{ border: 0 }}><CircularProgress /></TableCell>
                </TableRow>
                <TableRow style={{height: "150px"}}>
                  <TableCell colSpan={6} />
                </TableRow>
              </>
            )}
            {searchQuery.trim().length > 0 && visibleRows.length === 0 && (
              <TableRow>
                <TableCell colSpan={6} align='center'>
                  No entries found with {searchBy} <span style={{ fontWeight: 500 }}>&quot;{searchQuery}&quot;</span>
                </TableCell>
              </TableRow>
            )}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 40 * emptyRows - (searchQuery.trim() ? 1 : 0)
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </MuiTable>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component='div'
        count={searchRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  )
}
