import React from 'react'

import { Box, Typography, Grow, Button, Tooltip } from '@mui/material';
import CodeEditor from '@uiw/react-textarea-code-editor';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CircleIcon from '@mui/icons-material/Circle';
import PendingRoundedIcon from '@mui/icons-material/PendingRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { amber as muiAmberColor } from '@mui/material/colors';

import variables from '../../../../variables.module.scss';
import { AUTHZ_ACTIONS, authorizeAction } from '../../Auth/SamlAuth/Authorization';

const renderPodSettingStatus = (changing: boolean, active: boolean, error: boolean, length: number): JSX.Element => {
  let statusIndicator: JSX.Element = null

  if (active) {
    statusIndicator = <CheckCircleRoundedIcon fontSize='small' htmlColor={variables.successColor} />
  }

  if (!changing) {
    if (length === 0) {
      statusIndicator = <CircleIcon fontSize='small' htmlColor={variables.dataBackgroundColor} />
    }
  }

  if (changing) {
    statusIndicator = <PendingRoundedIcon fontSize='small' htmlColor={muiAmberColor[400]} />
  }

  if (error) {
    statusIndicator = <ErrorRoundedIcon fontSize='small' htmlColor={variables.failureColor} />
  }

  return statusIndicator
}

interface PodTargetSettingProps {
  icon: JSX.Element
  title: string
  placeholder: string

  handleValueChange: (val: string) => void

  value: string|Array<string>
  changing: boolean
  setChanging: (val: boolean) => void
  active: boolean
  error: boolean

  postValue: () => void
}

export const PodTargetSetting: React.FC<PodTargetSettingProps> = ({ icon, title, placeholder, handleValueChange, value, changing, setChanging, active, error, postValue }) => {
  return (
    <Box
      padding='8px'
      borderRadius='6px'
      bgcolor={variables.mainBackgroundColor}
      border={error ? `1px solid ${variables.failureColor}` : null}
      mb='10px'
    >
      <Box
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        marginBottom='10px'
      >
        <Box display='flex' alignItems='center' gap='10px'>
          {icon}
          <Typography
            variant='body2'
            fontFamily={variables.textFontFamily}
            color={variables.secondaryFontColor}
            textTransform='uppercase'
            fontWeight={600}
          >
            {title}
          </Typography>
        </Box>
        {renderPodSettingStatus(
          changing,
          active,
          error,
          value.length
        )}
      </Box>
      <Box maxHeight='120px' overflow='auto'>
        <CodeEditor
          disabled={!authorizeAction(AUTHZ_ACTIONS.CAN_UPDATE_TARGETED_PODS)}
          value={value}
          language='py'
          placeholder={placeholder}
          onChangeCapture={() => setChanging(true)}
          onChange={(event) => handleValueChange(event.target.value)}
          padding={8}
          style={{
            borderRadius: '4px',
            fontSize: 14,
            backgroundColor: `${variables.dataBackgroundColor}`,
            fontFamily:
              'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace'
          }}
        />
      </Box>
      {changing && (
        <Grow in={changing}>
          <Tooltip title='New pod targeting rule will affect all users' arrow>
            <Button
              variant='contained'
              onClick={postValue}
              color='success'
              sx={{ backgroundColor: variables.successColor, marginTop: '5px' }}
              fullWidth
              disableElevation
            >
              <Typography
                variant='body2'
                fontFamily={variables.textFontFamily}
                fontWeight={600}
                textTransform='none'
                lineHeight={1}
              >
                Apply
              </Typography>
            </Button>
          </Tooltip>
        </Grow>
      )}
    </Box>
  )
}
