enum EdgeTypes {
  Bandwidth = 'bandwidth',
  BandwidthRequest = 'bandwidthRequest',
  BandwidthResponse = 'bandwidthResponse',
  BandwidthCumulative = 'bandwidthCumulative',
  BandwidthCumulativeRequest = 'bandwidthCumulativeRequest',
  BandwidthCumulativeResponse = 'bandwidthCumulativeResponse',
  Throughput = 'throughput',
  ThroughputCumulative = 'throughputCumulative',
  Latency = 'latency'
}

export default EdgeTypes
