import React from 'react'

const MoreIcon: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='25'
      viewBox='0 0 24 25'
      fill='none'
    >
      <path
        d='M12 20.1897C11.45 20.1897 10.9793 19.994 10.588 19.6027C10.196 19.2107 10 18.7397 10 18.1897C10 17.6397 10.196 17.1687 10.588 16.7767C10.9793 16.3854 11.45 16.1897 12 16.1897C12.55 16.1897 13.021 16.3854 13.413 16.7767C13.8043 17.1687 14 17.6397 14 18.1897C14 18.7397 13.8043 19.2107 13.413 19.6027C13.021 19.994 12.55 20.1897 12 20.1897ZM12 14.1897C11.45 14.1897 10.9793 13.9937 10.588 13.6017C10.196 13.2104 10 12.7397 10 12.1897C10 11.6397 10.196 11.1687 10.588 10.7767C10.9793 10.3854 11.45 10.1897 12 10.1897C12.55 10.1897 13.021 10.3854 13.413 10.7767C13.8043 11.1687 14 11.6397 14 12.1897C14 12.7397 13.8043 13.2104 13.413 13.6017C13.021 13.9937 12.55 14.1897 12 14.1897ZM12 8.1897C11.45 8.1897 10.9793 7.9937 10.588 7.6017C10.196 7.21036 10 6.7397 10 6.1897C10 5.6397 10.196 5.16903 10.588 4.7777C10.9793 4.3857 11.45 4.1897 12 4.1897C12.55 4.1897 13.021 4.3857 13.413 4.7777C13.8043 5.16903 14 5.6397 14 6.1897C14 6.7397 13.8043 7.21036 13.413 7.6017C13.021 7.9937 12.55 8.1897 12 8.1897Z'
        fill='#416CDE'
      />
    </svg>
  )
}

export default MoreIcon
