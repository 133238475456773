import React from 'react'

import styles from './NavDropdown.module.sass'
import variables from '../../../variables.module.scss'

import { Menu, MenuItem, Dropdown } from '@mui/base'
import { ClickAwayListener, IconButton, Popper, Grow } from '@mui/material'

import {
  LoginRounded as LoginRoundedIcon,
  LogoutRounded as LogoutRoundedIcon,
  PendingActions as PendingActionsIcon
} from '@mui/icons-material'

import MoreIcon from './MoreIcon'

import { useSetRecoilState } from 'recoil'
import loginOpenAtom from '../../../recoil/loginOpen'
import jobsModalOpenAtom from "../../../recoil/jobsModalOpen";

import { LinkAdminConsole } from '../../../consts'
import AdminConsoleIcon from '../../UI/Misc/AdminConsoleIcon'
import { checkAuth, checkAuthEnabled, logout } from '../../UI/Auth/Auth'
import { AUTHZ_ACTIONS, authorizeAction } from '../../UI/Auth/SamlAuth/Authorization'
import { AuthorizeAction } from '../../UI/Auth/SamlAuth/AuthorizeAction'

export const NavDropdown: React.FC = () => {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)

  const handleClick = () => {
    setOpen(!open)
  }

  const setLoginOpen = useSetRecoilState(loginOpenAtom)

  const setJobsModalOpen = useSetRecoilState(jobsModalOpenAtom);

  const handleOpenJobsModal = () => {
    setOpen(false)
    setJobsModalOpen(true);
  }

  const handleOpenAdminPanel = () => {
    setOpen(false)
    window.open(LinkAdminConsole, "_blank")
  }

  const handleOpenLogin = () => {
    setOpen(false)
    setLoginOpen(true)
  }

  const handleLogout = () => {
    setOpen(false)
    logout()
  }

  const authDropdownItems = () => {
    if (!checkAuthEnabled()) {
      return
    }

    if (checkAuth()) {
      return (
        <MenuItem
          className={styles.DropdownItem}
          onClick={handleLogout}
        >
          <LogoutRoundedIcon htmlColor={variables.blueColor} />
          <span className={styles.DropdownItemText}>Logout</span>
        </MenuItem>
      )
    } else {
      return (
        <MenuItem
          className={styles.DropdownItem}
          onClick={handleOpenLogin}
        >
          <LoginRoundedIcon htmlColor={variables.blueColor} />
          <span className={styles.DropdownItemText}>Login</span>
        </MenuItem>
      )
    }
  }

  const popperModifiers = [
    {
      name: 'offset',
      options: {
        offset: [0, 10]
      }
    }
  ]

  return (
    <ClickAwayListener onClickAway={() => open && setOpen(false)}>
      <div style={{ position: 'relative' }}>
        <Dropdown open={open}>
          <IconButton
            className={styles.DropdownButton}
            ref={anchorRef}
            onClick={handleClick}
          >
            <MoreIcon />
          </IconButton>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement='bottom-end'
            transition
            disablePortal
            modifiers={popperModifiers}
            sx={{ zIndex: 1300 }}
          >
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: 'right top'
                }}
              >
                <div>
                  <Menu slotProps={{ listbox: { className: styles.Dropdown } }}>
                    <MenuItem className={`${styles.DropdownItem} ${!authorizeAction(AUTHZ_ACTIONS.CAN_USE_SCRIPTING) ? styles.DropdownItemDisabled : ''}`}
                      onClick={handleOpenJobsModal}
                      disabled={!authorizeAction(AUTHZ_ACTIONS.CAN_USE_SCRIPTING)}>
                      <PendingActionsIcon htmlColor={!authorizeAction(AUTHZ_ACTIONS.CAN_USE_SCRIPTING) ? variables.lightGrayColor : variables.blueColor} />
                      <span className={styles.DropdownItemText}>Jobs</span>
                    </MenuItem>
                    <AuthorizeAction action={AUTHZ_ACTIONS.SHOW_ADMIN_CONSOLE_LINK}>
                      <MenuItem className={styles.DropdownItem} onClick={handleOpenAdminPanel}>
                        <AdminConsoleIcon color={variables.blueColor} width={24} height={24} flat />
                        <span className={styles.DropdownItemText}>Admin Console</span>
                      </MenuItem>
                    </AuthorizeAction>
                    {authDropdownItems()}
                  </Menu>
                </div>
              </Grow>
            )}
          </Popper>
        </Dropdown>
      </div>
    </ClickAwayListener>
  )
}
