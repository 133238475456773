import { Entry } from '../../EntryListItem/Entry'
import NodeTypes from './NodeTypes'

export enum EndpointType {
  Source = 'src',
  Destination = 'dst',
}

export function composeNodeFilter(nodeType: string, entry: Entry, endpointType: EndpointType): string {
  switch (nodeType) {
  case NodeTypes.Name:
    return composeNameFilter(entry, endpointType)
  case NodeTypes.Namespace:
    return composeNamespaceFilter(entry, endpointType)
  case NodeTypes.Pod:
    return composePodFilter(entry, endpointType)
  case NodeTypes.EndpointSlice:
    return composeEndpointSliceFilter(entry, endpointType)
  case NodeTypes.Service:
    return composeServiceFilter(entry, endpointType)
  case NodeTypes.WorkerNode:
    return composeWorkerNodeFilter(entry, endpointType)
  }
}

function composeNameFilter(entry: Entry, endpointType: EndpointType): string {
  let filter = ''

  if (entry[endpointType]?.name) {
    filter += `src.name == "${entry[endpointType].name}" or dst.name == "${entry[endpointType].name}"`
  } else if (entry[endpointType]?.pod?.metadata?.name) {
    filter += `src.pod.metadata.name == "${entry[endpointType].pod.metadata.name}"`
    filter += ` or dst.pod.metadata.name == "${entry[endpointType].pod.metadata.name}"`
  } else if (entry[endpointType]?.endpointSlice?.metadata?.name) {
    filter += `src.endpointSlice.metadata.name == "${entry[endpointType].endpointSlice.metadata.name}"`
    filter += ` or dst.endpointSlice.metadata.name == "${entry[endpointType].endpointSlice.metadata.name}"`
  } else if (entry[endpointType]?.service?.metadata?.name) {
    filter += `src.service.metadata.name == "${entry[endpointType].service.metadata.name}"`
    filter += ` or dst.service.metadata.name == "${entry[endpointType].service.metadata.name}"`
  }

  return filter
}

function composeNamespaceFilter(entry: Entry, endpointType: EndpointType): string {
  let filter = ''

  if (entry[endpointType]?.namespace) {
    filter += `src.namespace == "${entry[endpointType].namespace}"`
    filter += ` or dst.namespace == "${entry[endpointType].namespace}"`
  } else if (entry[endpointType]?.name) {
    filter += `src.name == "${entry[endpointType].name}" or dst.name == "${entry[endpointType].name}"`
  } else {
    filter += `src.ip == "${entry[endpointType].ip}" or dst.ip == "${entry[endpointType].ip}"`
  }

  return filter
}

function composePodFilter(entry: Entry, endpointType: EndpointType): string {
  let filter = ''

  if (entry[endpointType]?.pod?.metadata?.name) {
    filter += `src.pod.metadata.name == "${entry[endpointType].pod.metadata.name}"`
    filter += ` or dst.pod.metadata.name == "${entry[endpointType].pod.metadata.name}"`
  }

  return filter
}

function composeEndpointSliceFilter(entry: Entry, endpointType: EndpointType): string {
  let filter = ''

  if (entry[endpointType]?.endpointSlice?.metadata?.name) {
    filter += `src.endpointSlice.metadata.name == "${entry[endpointType].endpointSlice.metadata.name}"`
    filter += ` or dst.endpointSlice.metadata.name == "${entry[endpointType].endpointSlice.metadata.name}"`
  }

  return filter
}

function composeServiceFilter(entry: Entry, endpointType: EndpointType): string {
  let filter = ''

  if (entry[endpointType]?.service?.metadata?.name) {
    filter += `src.service.metadata.name == "${entry[endpointType].service.metadata.name}"`
    filter += ` or dst.service.metadata.name == "${entry[endpointType].service.metadata.name}"`
  }

  return filter
}

function composeWorkerNodeFilter(entry: Entry, endpointType: EndpointType): string {
  let filter = ''

  if (entry[endpointType]?.pod) {
    filter += `src.pod.spec.nodeName == "${entry[endpointType].pod.spec.nodeName}"`
    filter += ` or dst.pod.spec.nodeName == "${entry[endpointType].pod.spec.nodeName}"`
  }

  return filter
}
