import { Entry } from '../../EntryListItem/Entry'
import NodeTypes from './NodeTypes'

export default function composeEdgeFilter(nodeType: string, entry: Entry) {
  let filter = ''

  if (nodeType === NodeTypes.Namespace) {
    filter += composeNamespaceEdgeFilter(entry)
  } else if (nodeType === NodeTypes.WorkerNode) {
    filter += composeWorkerNodeEdgeFilter(entry)
  } else {
    filter += composeFallbackEdgeFilter(entry)
  }

  return filter
}

function composeNamespaceEdgeFilter(entry: Entry): string {
  let filter = ''

  if (entry.src?.namespace)
    filter = `src.namespace == "${entry.src.namespace}"`
  else if (entry.src?.name) {
    filter = `src.name == "${entry.src.name}"`
  } else {
    filter = `src.ip == "${entry.src.ip}"`
  }

  if (entry.dst?.namespace) {
    filter += ` and dst.namespace == "${entry.dst.namespace}"`
  } else if (entry.dst?.name) {
    filter += ` and dst.name == "${entry.dst.name}"`
  } else {
    filter += ` and dst.ip == "${entry.dst.ip}"`
  }

  return filter
}

function composeWorkerNodeEdgeFilter(entry: Entry): string {
  let filter = ''

  if (entry.src?.pod && entry.dst?.pod) {
    filter = `src.pod.spec.nodeName == "${entry.src.pod.spec.nodeName}"`
    filter += ` and dst.pod.spec.nodeName == "${entry.dst.pod.spec.nodeName}"`
  }
  
  return filter
}

function composeFallbackEdgeFilter(entry: Entry): string {
  let filter = ''

  if (entry.src?.name) {
    filter = `src.name == "${entry.src.name}"`
  } else {
    filter = `src.ip == "${entry.src.ip}"`
  }

  filter += ` and src.namespace == "${entry.src.namespace}"`

  if (entry.dst?.name) {
    filter += ` and dst.name == "${entry.dst.name}"`
  } else {
    filter += ` and dst.ip == "${entry.dst.ip}"`
  }

  filter += ` and dst.namespace == "${entry.dst.namespace}"`

  return filter
}
