import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import QueryableStyle from './Queryable.module.sass';
import { useRecoilState } from "recoil";
import queryBuildAtom from "../../../recoil/queryBuild";

interface Props {
  query: string,
  joinCondition?: "or" | "and"
  style?: unknown,
  iconStyle?: unknown,
  className?: string,
  useTooltip?: boolean,
  tooltipStyle?: unknown,
  displayIconOnMouseOver?: boolean,
  displayImmediately?: boolean,
  flipped?: boolean,
  onAdded?: () => void
}

const splitByConditionsPattern = /\s+(or|and)\s+/
const identifyByConditionsPattern = /or|and/

const Queryable: React.FC<Props> = ({ query, joinCondition = "and", style, iconStyle, className, useTooltip = true, tooltipStyle = null, displayIconOnMouseOver = false, displayImmediately = false, flipped = false, onAdded, children }) => {
  const [showAddedNotification, setAdded] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [queryBuild, setQuerytemp] = useRecoilState(queryBuildAtom);
  const [queryChunks, setQueryChunks] = useState([])

  const onCopy = () => {
    setAdded(true)
  };

  const formatQuery = (query) => {
    if (query.match(splitByConditionsPattern)) {
      query = `(${query})`
    }

    return query
  }

  useEffect(() => {
    const queryChunks = query.split(splitByConditionsPattern)
    setQueryChunks(queryChunks)

    let timer;
    if (showAddedNotification) {

      const formattedQuery = formatQuery(query)
      setQuerytemp(queryBuild ? `${queryBuild} ${joinCondition} ${formattedQuery}` : formattedQuery);

      timer = setTimeout(() => {
        if (onAdded !== undefined) {
          onAdded()
        }
        setAdded(false);
      }, 1000);
    }
    return () => clearTimeout(timer);

    // eslint-disable-next-line
  }, [showAddedNotification, query, setQuerytemp]);

  useEffect(() => setShowTooltip(displayImmediately), [displayImmediately])

  const addButton = query ? <CopyToClipboard text={query} onCopy={onCopy}>
    <span
      className={QueryableStyle.QueryableIcon}
      title={`Add "${query}" to the filter`}
      style={iconStyle}>
      <AddCircleIcon fontSize="small" color="inherit" />
      {showAddedNotification && <span className={QueryableStyle.QueryableAddNotifier}>Added</span>}
    </span>
  </CopyToClipboard> : null;

  const displayIcon = displayImmediately ? QueryableStyle.displayIconImmediately : QueryableStyle.displayIconOnMouseOver

  const getFormattedQuery = () => (
    queryChunks.map((chunk, index) => (
      <React.Fragment key={index}>
        {chunk} {chunk.match(identifyByConditionsPattern) && <br/>}
      </React.Fragment>
    ))
  );


  return (
    <div className={`${QueryableStyle.QueryableContainer} ${displayIcon} ${className ? className : ''} ${displayIconOnMouseOver ? QueryableStyle.displayIconOnMouseOver : ''}`}
      style={style} onMouseOver={() => displayImmediately || setShowTooltip(true)} onMouseLeave={() => displayImmediately || setShowTooltip(false)}>
      {flipped && addButton}
      {children}
      {!flipped && addButton}
      {useTooltip && showTooltip && (query !== "") && <span data-cy={"QueryableTooltip"} className={QueryableStyle.QueryableTooltip} style={tooltipStyle}>
        {getFormattedQuery()}
      </span>}
    </div>
  );
};

export default Queryable;
