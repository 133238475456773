import Cookies from 'js-cookie'
import { SamlAuthCookieName } from '../../../../consts'
import * as jose from 'jose'

import { checkAuthEnabled } from '../Auth'
import { checkSamlEnabled } from './SamlAuth'

const extractJwtPayload = (): jose.JWTPayload => {
  const authJwtStr = Cookies.get(SamlAuthCookieName)
  return jose.decodeJwt(authJwtStr)
}

interface AuthzActionsPayload {
  canReplayTraffic?: boolean
  canDownloadPCAP?: boolean
  canUseScripting?: boolean
  canUpdateTargetedPods?: boolean
  showAdminConsoleLink?: boolean
}

export const AUTHZ_ACTIONS = {
  CAN_REPLAY_TRAFFIC: 'canReplayTraffic',
  CAN_DOWNLOAD_PCAP: 'canDownloadPCAP',
  CAN_USE_SCRIPTING: 'canUseScripting',
  CAN_UPDATE_TARGETED_PODS: 'canUpdateTargetedPods',
  SHOW_ADMIN_CONSOLE_LINK: 'showAdminConsoleLink',
}

const getSamlAuthzActionsPayload = (): Array<AuthzActionsPayload> => {
  const jwtPayload = extractJwtPayload()
  return jwtPayload['authorizedActions'] as Array<AuthzActionsPayload>
}

export const authorizeAction = (action: string): boolean => {
  if (checkAuthEnabled() && checkSamlEnabled()) {
    const authzActions = getSamlAuthzActionsPayload()
    return authzActions[action]
  }

  return true
}
