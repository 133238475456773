import React, { useEffect, useState } from 'react'
import { authorizeAction } from './Authorization'

interface AuthorizeActionProps {
  action: string
  children: React.ReactElement | React.ReactElement[]
}

export const AuthorizeAction: React.FC<AuthorizeActionProps> = ({ action, children }) => {
  const [authorized, setAuthorized] = useState(false)

  useEffect(() => setAuthorized(authorizeAction(action)))

  return <>{authorized ? children : null}</>
}
