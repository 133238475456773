import React from "react";
import styles from './TcpStream.module.sass';
import Queryable from "../../UI/Queryable/Queryable";
import { Button, Tooltip } from "@mui/material";
import { HubBaseUrl } from "../../../consts";
import useWindowDimensions, { useTcpStreamTextsByWidth } from "../../../hooks/WindowDimensionsHook";
import { TcpReplayDialog } from "./TcpReplayDialog";
import { Node } from "../../EntryListItem/Entry";
import { useRecoilValue } from "recoil";
import focusedRecordAtom from "../../../recoil/focusedRecord";
import { parse as parseContentDispositionAttachment } from "content-disposition-attachment";
import { AUTHZ_ACTIONS, authorizeAction } from "../../UI/Auth/SamlAuth/Authorization";

interface EntryProps {
  index: number;
  stream: string;
  worker: string;
  node: Node;
  color: string;
  ip: string;
  port: string;
  layer4: string;
}

export const TcpStream: React.FC<EntryProps> = ({ index, stream, worker, node, color, ip, port, layer4 }) => {

  const { width } = useWindowDimensions();
  const { tcpStream, indexText, nodeText, tcpReplay, downloadPcap } = useTcpStreamTextsByWidth(width, layer4);

  const focusedRecord = useRecoilValue(focusedRecordAtom);

  const [pcapFetched, setPcapFetched] = React.useState(false);
  const [pcapReady, setPcapReady] = React.useState(false);
  const [pcapFilename, setPcapFilename] = React.useState('');
  const [pcapUrl, setPcapUrl] = React.useState('');

  const pcapDownloadRef = React.useRef<HTMLAnchorElement>(null);

  const handleDownloadPcapClick = () => {
    if (pcapReady && pcapDownloadRef.current) {
      pcapDownloadRef.current.click();
    }
  };

  const getPcapPath = () => (
    `${HubBaseUrl}/pcaps/download/${worker}/${stream}?r=${encodeURIComponent(focusedRecord)}`
  )

  const peekPcap = async () => {
    const response = await fetch(getPcapPath());
    setPcapFetched(true);

    if (!response.ok || response.status === 404) {
      return
    }

    const contentDispositionHeader = response.headers.get('Content-Disposition');

    const parseResult = parseContentDispositionAttachment(contentDispositionHeader)
    if (!parseResult.attachment) {
      return
    }

    setPcapFilename(parseResult.filename)

    const arrayBuffer = await response.arrayBuffer();
    if (arrayBuffer.byteLength === 0) {
      return;
    }

    const blob = new Blob([arrayBuffer], { type: 'application/octet-stream' });
    const downloadUrl = URL.createObjectURL(blob);
    setPcapUrl(downloadUrl)
  }

  React.useEffect(() => {
    if (pcapFilename && pcapUrl) {
      setPcapReady(true)
    }
  }, [pcapFilename, pcapUrl])

  React.useEffect(() => {
    if (!worker || !stream) {
      return
    }

    peekPcap()

    return () => {
      if (pcapUrl) {
        URL.revokeObjectURL(pcapUrl);
      }
    };
  }, [])

  const getPcapTooltipText = React.useCallback(() => {
    let tooltipText = `File isn't available. To extend retention period, increase PCAP TTL or use the Traffic Recorder.`;

    if (pcapReady) {
      tooltipText = `Download this ${layer4} stream in PCAP: ${stream}`;
    }

    if (!authorizeAction(AUTHZ_ACTIONS.CAN_DOWNLOAD_PCAP)) {
      tooltipText = `Sorry, you are unauthorized to use this action.`;
    }

    return tooltipText;
  }, [pcapReady])

  return <React.Fragment>
    <div className={`${styles.row}`}>

      <div className={`${styles.separator}`}>
        <span
          className={`${styles.title}`}
        >
          {tcpStream}
        </span>
        <Queryable
          query={`node.ip == "${node.ip}" and stream == "${stream}"`}
          displayIconOnMouseOver={true}
          flipped={true}
          iconStyle={{ marginRight: "10px" }}
        >
          <a
            style={{ textDecoration: "none", cursor: pcapReady ? 'pointer' : 'auto' }}
            href="javascript:void(0);"
            onClick={handleDownloadPcapClick}
          >
            <span
              style={{ color: color, fontSize: "14px" }}
              title={`${layer4} stream in the worker: ${worker}`}
            >
              {worker}/{stream}
            </span>
          </a>
        </Queryable>
      </div>

      <div className={`${styles.separator}`}>
        <span
          className={`${styles.title} ${styles.marginLeft10}`}
        >
          {indexText}
        </span>
        <Queryable
          query={`index == ${index}`}
          displayIconOnMouseOver={true}
          flipped={true}
          iconStyle={{ marginRight: "10px" }}
        >
          <a
            style={{ textDecoration: "none" }}
            href={`${HubBaseUrl}/item/${worker}/${stream}-${index}?r=${encodeURIComponent(focusedRecord)}&field=data`}
            target="_blank"
            rel="noreferrer"
          >
            <span
              style={{ color: color, fontSize: "14px" }}
              title={`The index of the item in this ${layer4} stream: ${stream}`}
            >
              {index}
            </span>
          </a>
        </Queryable>
      </div>

      <div className={`${styles.separator} ${styles.nodeWrapper}`}>
        <span
          className={`${styles.title} ${styles.marginLeft10}`}
        >
          {nodeText}
        </span>
        <Queryable
          query={`node.name == "${node.name}"`}
          displayIconOnMouseOver={true}
          flipped={true}
          iconStyle={{ marginRight: "10px" }}
        >
          <span
            style={{ color: color, fontSize: "14px" }}
            title={`The node which this worker runs on: ${stream}`}
          >
            {node.name}
          </span>
        </Queryable>
      </div>

      <div className={`${styles.separator} ${styles.replayButtonWrapper}`}>
        <TcpReplayDialog
          color={color}
          node={node.name}
          tcpReplay={tcpReplay}
          stream={stream}
          worker={worker}
          ip={ip}
          port={port}
          layer4={layer4}
        />
      </div>

      {pcapFetched && <div className={`${styles.separator} ${styles.pcapButtonWrapper}`}>
        <Tooltip
          arrow
          placement={pcapReady ? 'top' : 'top-start'}
          title={getPcapTooltipText()}
        >
          <div>
            <Button
              variant='contained'
              className={`${styles.marginLeft10} ${styles.button}`}
              style={ pcapReady ? { backgroundColor: color } : {} }
              disabled={!authorizeAction(AUTHZ_ACTIONS.CAN_DOWNLOAD_PCAP) || !pcapReady}
              onClick={handleDownloadPcapClick}
            >
              {downloadPcap}
            </Button>
          </div>
        </Tooltip>
      </div>}
      <a ref={pcapDownloadRef} href={pcapUrl} download={pcapFilename} style={{ display: 'none' }}></a>
    </div>
  </React.Fragment>
}
