import React from 'react'
import Tooltip from '../../UI/Tooltip/Tooltip'
import CodeEditor from '@uiw/react-textarea-code-editor'
import variables from '../../../variables.module.scss'
import { Button, Typography } from '@mui/material'

interface AppliedSideFilterProps {
  name: string
  icon: JSX.Element
  filter: string
}

export const AutoAppliedFilter: React.FC<AppliedSideFilterProps> = ({
  name,
  icon,
  filter
}) => {
  return (
    <Tooltip
      placement='bottom-start'
      title={
        <CodeEditor
          disabled
          value={filter}
          language='py'
          padding={8}
          style={{
            borderRadius: '4px',
            fontSize: 14,
            backgroundColor: `${variables.dataBackgroundColor}`,
            fontFamily:
              'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace'
          }}
        />
      }
      arrow
      variant='styled'
      arrowClass='styled'
      classes={{
        tooltip: 'appliedFiltersTooltip',
        arrow: 'appliedFiltersTooltipArrow'
      }}
    >
      <Button
        size='small'
        sx={{
          padding: '4px 6px',
          backgroundColor: variables.dataBackgroundColor
        }}
      >
        {icon}
        <Typography
          variant='body2'
          fontSize={12}
          fontFamily={variables.textFontFamily}
          color={variables.fontColor}
          fontWeight={600}
          whiteSpace='nowrap'
          sx={{ marginLeft: '5px' }}
        >
          {name}
        </Typography>
      </Button>
    </Tooltip>
  )
}
