import React from 'react'

import { Box, Typography, Button, Paper, Link } from '@mui/material'

import { triggerSamlAuthPath } from '../SamlAuth'
import { HubBaseUrl } from '../../../../../consts'

import styles from './SamlSignInForm.module.sass'

import ShieldIcon from '@mui/icons-material/Shield'
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import variables from '../../../../../variables.module.scss'
import logoVertical from '../../../../../assets/logoVertical.svg'

const triggerSamlAuth = () => {
  window.location.href = `${HubBaseUrl}${triggerSamlAuthPath}`
}

const getSamlIdpName = () => {
  const idpMetadataUrl = new URL(window.__RUNTIME_CONFIG__.REACT_APP_AUTH_SAML_IDP_METADATA_URL)
  return idpMetadataUrl.hostname
}

export const SamlSignInForm: React.FC = () => {
  return (
    <Box className={styles.SamlSignInForm}>
      <img className='logo' src={logoVertical} alt='logo' />
      <Typography
        variant='h5'
        fontFamily={variables.textFontFamily}
        fontWeight={700}
        color='#A0B2FF'
      >
        Dashboard
      </Typography>
      <Typography
        variant='h4'
        fontFamily={variables.textFontFamily}
        fontWeight={700}
        color={variables.fontColor}
        align='center'
      >
        We need to authorize you, first.
      </Typography>
      <Typography
        variant='body1'
        fontFamily={variables.textFontFamily}
        color={variables.secondaryFontColor}
        align='center'
      >
        By continuing, you agree to the <br />
        <Link
          target='_blank'
          rel='noopener'
          color={variables.blueColor}
          underline='hover'
          href='https://www.kubeshark.co/privacy-policy'
          sx={{ fontWeight: 700 }}
        >
          Company&apos;s Privacy Statement
        </Link>
        &nbsp;and&nbsp;
        <Link
          target='_blank'
          rel='noopener'
          color={variables.blueColor}
          underline='hover'
          href='https://www.kubeshark.co/terms-of-service'
          sx={{ fontWeight: 700 }}
        >
          Terms of Service
        </Link>
        .
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '16px' }}>
        <Button
          size='large'
          variant='contained'
          startIcon={
            <VpnKeyIcon htmlColor='#ffffff' style={{ marginRight: '10px' }} />
          }
          style={{ textTransform: 'none', borderRadius: '6px' }}
          onClick={triggerSamlAuth}
        >
          <Typography
            variant='body1'
            fontFamily={variables.textFontFamily}
            fontWeight={600}
            color='#FFFFFF'
          >
            Sign in with SSO
          </Typography>
        </Button>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
          <SupervisorAccountIcon fontSize='small' htmlColor={variables.grayColor} />
          <Typography
            variant='body2'
            fontFamily={variables.textFontFamily}
            color={variables.grayColor}
            fontWeight={500}
          >
            {getSamlIdpName()}
          </Typography>
        </Box>
      </Box>
      <Paper>
        <Box className={styles.SamlNotice}>
          <ShieldIcon htmlColor={variables.blueColor} />
          <Typography
            variant='body2'
            fontFamily={variables.textFontFamily}
            fontWeight={500}
            color={variables.fontColor}
          >
            This Kubeshark installation is protected with&nbsp;
            <Link
              target='_blank'
              rel='noopener'
              color={variables.blueColor}
              underline='hover'
              href='https://docs.kubeshark.co/en/authentication#saml'
              sx={{ fontWeight: 700 }}
            >
              SAML auth
            </Link>
            .
          </Typography>
        </Box>
      </Paper>
    </Box>
  )
}
