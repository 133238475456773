import React from "react";
import styles from "./Misc.module.sass"
import Button from '@mui/material/Button';
import { LinkDocs, LinkAdminConsole } from "../../../consts";
import AdminConsoleIcon from "./AdminConsoleIcon";
import { useCommonStyles } from "../../../helpers/commonStyle";
import variables from "../../../variables.module.scss"
import { useSetRecoilState } from "recoil";
import scriptingModalOpenAtom from "../../../recoil/scriptingModalOpen/atom";
import ScriptingIcon from "./ScriptingIcon";
import DocsIcon from "./DocsIcon";
import { Link } from "@mui/material";
import { AUTHZ_ACTIONS, authorizeAction } from "../Auth/SamlAuth/Authorization";

interface GenericProps {
  className?: string,
}

export const DocsLink: React.FC<GenericProps> = () => {
  return <Link title="Documentation" className={styles.DocsLink} href={LinkDocs} target="_blank" rel="noopener">
    <DocsIcon></DocsIcon>
    <span>Docs</span>
  </Link>
}

export const AdminConsoleButton: React.FC<GenericProps> = () => {
  const commonClasses = useCommonStyles();

  return <Button
    variant="contained"
    size="large"
    title="Admin Console"
    startIcon={<AdminConsoleIcon />}
    className={commonClasses.button + " " + commonClasses.imagedButton}
    style={{ textTransform: 'unset', backgroundColor: variables.fontColor }}
    onClick={()=> window.open(LinkAdminConsole, "_blank")}
    sx={{ height: "30px" }}
  >
    Admin Console
  </Button>
}

export const ScriptingButton: React.FC<GenericProps> = () => {
  const commonClasses = useCommonStyles()

  const setScriptingModalOpen = useSetRecoilState(scriptingModalOpenAtom);

  const handleScriptingModal = () => {
    setScriptingModalOpen(true);
  }

  return <Button
    startIcon={<ScriptingIcon />}
    size="large"
    title="Scripting"
    variant="contained"
    className={commonClasses.button + " " + commonClasses.imagedButton}
    onClick={handleScriptingModal}
    disabled={!authorizeAction(AUTHZ_ACTIONS.CAN_USE_SCRIPTING)}
    style={{ textTransform: 'unset' }}>
    Scripting
  </Button>
}