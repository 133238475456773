import React from 'react'

const DocsIcon: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='19'
      viewBox='0 0 18 19'
      fill='none'
    >
      <g clipPath='url(#clip0_694_8347)'>
        <path
          d='M11.0453 5.23495H10.5V0.189697H3.68252C3.00377 0.189697 2.45477 0.738697 2.45477 1.4167V16.9627C2.45477 17.6407 3.00377 18.1897 3.68177 18.1897H14.3183C14.9963 18.1897 15.5453 17.6407 15.5453 16.9627V5.23495H11.0453ZM10.6365 13.0762H5.31752V12.0532H10.635V13.0762H10.6365ZM12.6818 10.6214H5.31827V9.59845H12.6818V10.6214ZM12.6818 8.1667H5.31827V7.14445H12.6818V8.1667ZM11.0453 4.6897H15.5453L11.0453 0.189697V4.6897Z'
          fill='#416CDE'
        />
      </g>
      <defs>
        <clipPath id='clip0_694_8347'>
          <rect
            width='18'
            height='18'
            fill='white'
            transform='translate(0 0.189697)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default DocsIcon
