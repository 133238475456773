import React from 'react'

export const FullScreenModeIcon: React.FC = () => {
  return (
    <svg
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect y='0.379639' width='30' height='30' rx='6' fill='#F7F9FC' />
      <path
        d='M5 10.5854V7.25464C5 6.75736 5.19754 6.28044 5.54917 5.92881C5.90081 5.57718 6.37772 5.37964 6.875 5.37964H23.125C23.6223 5.37964 24.0992 5.57718 24.4508 5.92881C24.8025 6.28044 25 6.75736 25 7.25464V11.5046'
        stroke='#416CDE'
        strokeWidth='2'
        strokeMiterlimit='2.3662'
        strokeLinecap='round'
      />
      <path
        d='M23.2353 13.3796H6.76471C5.79009 13.3796 5 14.2949 5 14.8796V24.3208C5 24.9056 5.79009 25.3796 6.76471 25.3796H23.2353C24.2099 25.3796 25 24.9056 25 24.3208V14.8796C25 14.2949 24.2099 13.3796 23.2353 13.3796Z'
        fill='white'
        stroke='#BCCEFD'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeDasharray='3 3'
      />
      <path
        d='M18 14.0716C18.1994 14.0716 18.3798 13.9531 18.4589 13.77C18.5381 13.587 18.5009 13.3744 18.3643 13.2291L15.3643 10.0372C15.2698 9.93666 15.138 9.87964 15 9.87964C14.862 9.87964 14.7302 9.93666 14.6357 10.0372L11.6357 13.2291C11.4991 13.3744 11.4619 13.587 11.5411 13.77C11.6202 13.9531 11.8006 14.0716 12 14.0716H13.5079L13.5079 19.3796C13.5079 19.5122 13.5606 19.6394 13.6544 19.7332C13.7482 19.827 13.8753 19.8796 14.0079 19.8796L15.9921 19.8796C16.2682 19.8796 16.4921 19.6558 16.4921 19.3796L16.4921 14.0716H18Z'
        fill='#416CDE'
        stroke='white'
        strokeLinejoin='round'
      />
    </svg>
  )
}
