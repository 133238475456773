import * as React from 'react';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { Button, Dialog, DialogTitle, Tabs, Tab } from '@mui/material';

import variables from '../../variables.module.scss'
import styles from './CreateRecordingDialog.module.sass';
import { StartRecordingJobView } from './StartRecordingJobView/StartRecordingJobView';
import { AllRecordingsView } from './AllRecordingsView/AllRecordingsView';

import { useRecoilState } from 'recoil'
import createRecordingDialogOpenAtom from "../../recoil/createRecordingDialogOpen";

export interface CreateRecordingDialogProps {
  buttonClassName: string
}

export const CreateRecordingDialog: React.FC<CreateRecordingDialogProps> = ({ buttonClassName }) => {
  const [open, setOpen] = useRecoilState(createRecordingDialogOpenAtom);

  const [currentTab, setCurrentTab] = React.useState(0);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
    setCurrentTab(0)
  }

  React.useEffect(() => !open && handleClose(), [open])

  return (
    <>
      <Button
        title='Create a new recording'
        variant='contained'
        className={buttonClassName}
        color='primary'
        onClick={handleClickOpen}
      >
        <RadioButtonCheckedIcon fontSize='inherit'></RadioButtonCheckedIcon>
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        style={{ color: variables.blueColor }}
      >
        <DialogTitle style={{ fontWeight: 'bold', color: variables.blueColor }}>
          <Tabs value={currentTab} onChange={handleChangeTab} aria-label="basic tabs example">
            <Tab label="Start a Recording Job" className={styles.Tab} />
            <Tab label="All Recordings" className={styles.Tab} />
          </Tabs>
        </DialogTitle>
        {currentTab === 0 && <StartRecordingJobView />}
        {currentTab === 1 && <AllRecordingsView switchToStartRecordingJob={() => setCurrentTab(0)} />}
      </Dialog>
    </>
  )
}
