import React from 'react'
import { useRecoilState } from 'recoil'
import fullscreenViewAtom from '../../../recoil/fullscreenView/atom'
import { Box, ButtonBase, Tooltip } from '@mui/material'
import { FullScreenModeIcon } from './FullscreenViewIcon'

export const FullscreenViewButton: React.FC = () => {
  const [fullscreenView, setFullscreenView] = useRecoilState(fullscreenViewAtom)
  return (
    <Tooltip
      arrow
      placement='left'
      title={fullscreenView ? 'Exit fullscreen view' : 'Enter fullscreen view'}
    >
      <ButtonBase
        onClick={() => setFullscreenView(!fullscreenView)}
        sx={{ borderRadius: '6px' }}
      >
        <Box
          sx={{
            height: '30px',
            transform: fullscreenView ? 'rotate(180deg)' : 'unset'
          }}
        >
          <FullScreenModeIcon />
        </Box>
      </ButtonBase>
    </Tooltip>
  )
}
