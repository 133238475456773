import React from 'react'

import variables from '../../variables.module.scss'

export const NavDivider: React.FC = () => {
  return (
    <div
      style={{
        width: '1px',
        height: '16px',
        backgroundColor: variables.dataBackgroundColor
      }}
    ></div>
  )
}
