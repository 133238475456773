import React from "react";
import { TrafficViewer } from "../../TrafficViewer/TrafficViewer"
import "../../../index.sass"

interface TrafficPageProps {
  getLicense: () => void;
}

export const TrafficPage: React.FC<TrafficPageProps> = ({ getLicense }) => {
  return (
    <TrafficViewer getLicense={getLicense} />
  );
};
