import React from 'react'
import ScrollableFeedVirtualized from 'react-scrollable-feed-virtualized'

import makeStyles from '@mui/styles/makeStyles';

import queryBuildAtom from "../../../recoil/queryBuild";
import { useSetRecoilState } from 'recoil'

import { Filters } from '../../Filters/Filters'
import { EntriesList } from '../../EntriesList/EntriesList'
import { EntryDetailed } from '../../EntryDetailed/EntryDetailed'

import variables from '../../../variables.module.scss';
import styles from "./ApiCallsView.module.sass"
import EntriesListStyles from '../../EntriesList/EntriesList.module.sass';
import { Box, Typography } from '@mui/material';
import { FullscreenViewButton } from '../../UI/FullscreenView/FullscreenViewButton';

interface ApiCallsViewProps {
  listEntryREF: React.LegacyRef<HTMLDivElement>;
  reopenConnection?: () => void;
  onSnapBrokenEvent: () => void;
  isSnappedToBottom: boolean;
  setIsSnappedToBottom: (state: boolean) => void;
  scrollableRef: React.MutableRefObject<ScrollableFeedVirtualized>;
}

const useLayoutStyles = makeStyles(() => ({
  details: {
    height: "100%",
    padding: "12px 24px",
    borderRadius: 4,
    background: variables.headerBackgroundColor,
  },

  viewer: {
    display: "flex",
    overflowY: "auto",
    height: "calc(100% - 70px)",
    padding: 5,
    paddingBottom: 0,
    overflow: "auto",
  },
}));

export const ApiCallsView: React.FC<ApiCallsViewProps> = ({ listEntryREF, reopenConnection, onSnapBrokenEvent, isSnappedToBottom, setIsSnappedToBottom, scrollableRef }) => {
  const setQueryBuild = useSetRecoilState(queryBuildAtom);

  const classes = useLayoutStyles();

  return (
    <div className={styles.TrafficPageContainer}>
      <div className={styles.TrafficPageListContainer}>
        <Box position='absolute' left={24} right={24} width='calc(100% - 42px)' zIndex={1} bgcolor={variables.dataBackgroundColor}>
          <Filters
            reopenConnection={reopenConnection}
            onQueryChange={(q) => {
              setQueryBuild(q?.trim())
            }}
          />
        </Box>
        <div className={EntriesListStyles.container}>
          <EntriesList
            listEntryREF={listEntryREF}
            onSnapBrokenEvent={onSnapBrokenEvent}
            isSnappedToBottom={isSnappedToBottom}
            setIsSnappedToBottom={setIsSnappedToBottom}
            scrollableRef={scrollableRef}
          />
        </div>
      </div>
      <Box flex='0 0 50%' width='45vw' marginTop='9px' paddingRight='24px'>
        <Box display='flex' alignItems='center' justifyContent='space-between' marginBottom='8px'>
          <Typography variant='body1' fontFamily='Roboto' fontSize='14px' color={variables.secondaryFontColor}>API Calls Details</Typography>
          <FullscreenViewButton />
        </Box>
        <div className={classes.details} id='rightSideContainer'>
          <EntryDetailed />
        </div>
      </Box>
    </div>
  )
}
