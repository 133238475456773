import React, { useMemo } from "react";
import styles from './EntriesList.module.sass';
import ScrollableFeedVirtualized from "react-scrollable-feed-virtualized";
import down from "./assets/downImg.svg";
import { useInterval } from "../../helpers/interval";
import { EntryItem } from "../EntryListItem/EntryListItem";
import { HubBaseUrl } from "../../consts";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import {
  Link,
} from "@mui/material";
import { SyntaxHighlighter } from "../UI/SyntaxHighlighter";
import { useRecoilValue, useSetRecoilState } from "recoil";
import entriesAtom from "../../recoil/entries";
import loginOpenAtom from "../../recoil/loginOpen";
import sizeCapturedAtom from "../../recoil/sizeCaptured";
import { TrafficStatsFooter } from "../TrafficViewer/TrafficStatsFooter/TrafficStatsFooter";
import trafficPlaybackAtom, { TRAFFIC_PLAYBACK_STOPPED } from "../../recoil/trafficPlayback/atom";
import { checkAuth, checkAuthEnabled } from "../UI/Auth/Auth";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown, string>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface EntriesListProps {
  listEntryREF: React.LegacyRef<HTMLDivElement>;
  onSnapBrokenEvent: () => void;
  isSnappedToBottom: boolean;
  setIsSnappedToBottom: (state: boolean) => void;
  scrollableRef: React.MutableRefObject<ScrollableFeedVirtualized>;
}

export const EntriesList: React.FC<EntriesListProps> = ({
  listEntryREF,
  onSnapBrokenEvent,
  isSnappedToBottom,
  setIsSnappedToBottom,
  scrollableRef,
}) => {
  const [openLicenseRequiredDialog, setOpenLicenseRequiredDialog] = React.useState(false);

  const entries = useRecoilValue(entriesAtom);
  const setLoginOpen = useSetRecoilState(loginOpenAtom);
  const setSizeCaptured = useSetRecoilState(sizeCapturedAtom);
  const trafficPlayback = useRecoilValue(trafficPlaybackAtom)

  const handleCloseLicenseRequiredDialog = () => {
    setOpenLicenseRequiredDialog(false);
  };

  useInterval(async () => {
    if (trafficPlayback === TRAFFIC_PLAYBACK_STOPPED) {
      return
    }

    let status: number;

    if (checkAuthEnabled() && !checkAuth()) {
      window.location.reload()
      return
    }

    fetch(`${HubBaseUrl}/pcaps/total-size`)
      .then(response => {
        status = response.status;
        return response.text();
      })
      .then(rawJson => {
        return rawJson ? JSON.parse(rawJson) : {}
      })
      .then(data => {
        switch (status) {
        case 418:
          setLoginOpen(false);
          if (data?.EnabledFeatures?.length > 0) {
            setOpenLicenseRequiredDialog(true);
          }
          break;
        case 403:
          setLoginOpen(true);
          break;
        default:
          setSizeCaptured(data.total)
          break;
        }
      })
      .catch(err => {
        console.error(err);
      });
  }, 3000, true);

  const memoizedEntries = useMemo(() => {
    return entries;
  }, [entries]);


  return <React.Fragment>
    <div className={styles.list}>
      <div id="list" ref={listEntryREF} className={styles.list}>
        <ScrollableFeedVirtualized ref={scrollableRef} itemHeight={48} marginTop={10} onSnapBroken={onSnapBrokenEvent}>
          {false /* It's because the first child is ignored by ScrollableFeedVirtualized */}
          {memoizedEntries.map(entry => {
            return <EntryItem
              key={entry.key}
              entry={entry}
              style={{}}
              headingMode={false}
            />
          })}
        </ScrollableFeedVirtualized>
        <button type="button"
          title="Snap to bottom"
          className={`${styles.btnLive} ${isSnappedToBottom ? styles.hideButton : styles.showButton}`}
          onClick={() => {
            scrollableRef.current.jumpToBottom();
            setIsSnappedToBottom(true);
          }}>
          <img alt="down" src={down} />
        </button>
      </div>
      <div style={{ marginRight: "20px"}}>
        <TrafficStatsFooter />
      </div>
    </div>
    <div>
      <Dialog
        open={openLicenseRequiredDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseLicenseRequiredDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Oops... Something went wrong!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {"When using the CLI, many problems can be solved by installing the latest CLI version:"}
            <SyntaxHighlighter
              showLineNumbers={false}
              code={`sh <(curl -Ls https://kubeshark.co/install)`}
              language="bash"
            />
            {" If the issue persists, please refer to the "}
            <Link href="https://docs.kubeshark.co/en/troubleshooting" underline="hover" target="_blank">troubleshooting</Link>
            {" page."}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleCloseLicenseRequiredDialog}
            style={{ margin: 10 }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  </React.Fragment>;
};
